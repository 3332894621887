import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './landing.scss';

import alan from 'assets/images/landing/alan.jpeg';
import bytedanceLogo from 'assets/images/landing/bytedanceLogo.png';
import checkIcon from 'assets/images/landing/check_icon.svg';
import conversion from 'assets/images/landing/conversion.png';
import copy from 'assets/images/landing/copy.png';
import danis from 'assets/images/landing/danis.jpeg';
import doodle from 'assets/images/landing/doodle.svg';
import doodleString from 'assets/images/landing/doodle_string.svg';
import googleLogo from 'assets/images/landing/google_logo.png';
import hero from 'assets/images/landing/hero.png';
import kuber from 'assets/images/landing/kuber.jpeg';
import { ReactComponent as NavbarLogo } from 'assets/images/landing/logo.svg';
import miroLogo from 'assets/images/landing/miroLogo.png';
import polishUI from 'assets/images/landing/polish-ui.png';
import spiral from 'assets/images/landing/spiral.svg';
import testimonials from 'assets/images/landing/testimonials.png';
import workstreamLogo from 'assets/images/landing/workstreamLogo.png';

import Login from 'Components/Login/Login';

import { ROUTES } from 'routes/route.constants';

import { getCurrentYear } from 'utlis/date';

export const LandingPageUI = ({ installButton, InstallExtensionButton, fromPluginAuth }) => {
  return (
    <div className="landing-wrapper">
      <div
        data-w-id="18f5e840-a1f4-abea-0a7d-01cd0522445d"
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar-logo-left w-nav"
      >
        <div className="navbarcontainer w-container">
          <div className="navbar-content">
            <div className="navbar-brand">
              <div className="logo">
                <NavbarLogo />
              </div>
            </div>
            <nav role="navigation" className="navbar-menu w-nav-menu">
              <a href="#features" className="navbar-link w-nav-link">
                <div className="text">
                  Features
                </div>
              </a>
              <a href="#pricing" className="navbar-link w-nav-link">
                <div className="text">
                  Pricing
                </div>
              </a>
            </nav>
          </div>
          <div
            className="menu-button w-nav-button"
            onClick={handleShowNavMenu}
            style={{ WebkitUserSelect: 'text' }}
            aria-label="menu"
            role="button"
            tabIndex="0"
            aria-controls="w-nav-overlay-0"
            aria-haspopup="menu"
            aria-expanded="true"
          >
            <div className="w-icon-nav-menu" />
          </div>
        </div>
        <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
        >
          <nav
            role="navigation"
            className="navbar-menu w-nav-menu"
            onClick={handleShowNavMenu}
            data-nav-menu-open=""
          >
            <a
              href="#"
              className="navbar-link w-nav-link w--nav-link-open"
              style={{ maxWidth: '1200px' }}
            >
              <div className="text">
                Features
              </div>
            </a>
            <a
              href="#pricing"
              className="navbar-link w-nav-link w--nav-link-open"
              style={{ maxWidth: '1200px' }}
            >
              <div className="text">
                Pricing
              </div>
            </a>
          </nav>
        </div>
      </div>
      <div data-w-id="d8094754-af3a-caf3-44b7-73159b57d910" className="hero-stack">
        <div className="small-container">
          <div className="title-section">
            <div className="content">
              <a className="button-3">
                From the creators of Siri
              </a>
              <div data-w-id="d8094754-af3a-caf3-44b7-73159b57d914" className="h1">
                AI reviews: Enhance websites & apps
              </div>
              <div data-w-id="d8094754-af3a-caf3-44b7-73159b57d916" className="h5">
                Instantly boost UX and engagement with AI-driven recommendations trained on best practices
              </div>
            </div>
            <div className="actions">
              {installButton}
              {InstallExtensionButton}
            </div>
          </div>
        </div>
      </div>
      <img
        src={hero}
        loading="lazy"
        sizes="(max-width: 479px) 90vw,
            (max-width: 767px) 94vw,
            (max-width: 991px) 95vw,
            (max-width: 1439px) 96vw,
            1199.9921875px"
        alt=""
        className="image-61"
      />
      <div data-w-id="5acd1e33-f2e5-feec-dbde-25cfc05ef646" className="logo-title">
        <div className="title">
          DesignPro helps designers, product managers, & marketers design better and faster
        </div>
        <div className="content-10">
          <div className="logos f2wf-columns-3">
            <div className="logo-wrapper">
              <img
                src={googleLogo}
                loading="lazy"
                alt="google"
                className="logo"
                width={288}
              />
            </div>
            <div className="logo-wrapper">
              <img
                src={bytedanceLogo}
                loading="lazy"
                width="192"
                alt="bytedance"
                className="logo logo-2"
              />
            </div>
            <div className="logo-wrapper">
              <img
                src={workstreamLogo}
                loading="lazy"
                alt="workstream"
                className="logo"
                width={210}
                sizes="(max-width: 479px) 100px, (max-width: 767px) 11vw, (max-width: 1439px) 9vw, 124.4921875px"
              />
            </div>
            <div className="logo-wrapper">
              <img
                src={miroLogo}
                loading="lazy"
                alt="miro"
                className="logo"
                width={230}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="container-3 f2wf-columns-3">
          <div className="column-12">
            <div className="content-12">
              <h1 className="h2">
                💬 Improve your copy
              </h1>
              <p className="h6">
                Get copy suggestions to increase user engagement on
                websites, mobile apps, alerts, and modals. Enhance
                clarity on product benefits and guide users more
                easily through websites and apps.
              </p>
              <a
                href="https://www.figma.com/community/plugin/1339202278007297015"
                className="button-4 w-button"
              >
                Install the Figma Plugin
              </a>
            </div>
          </div>
          <div className="column-13">
            <div className="image-wrapper-10">
              <img
                src={copy}
                loading="lazy"
                sizes="(max-width: 479px) 80vw,
                (max-width: 767px) 87vw,
                (max-width: 991px) 90vw,
                (max-width: 1439px) 51vw,
                720px"
                alt=""
                className="feature-image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="container-3 f2wf-columns-3">
          <div className="column-12">
            <div className="content-12">
              <h1 className="h2">
                🎨 Audit your UI
              </h1>
              <p className="h6">
                Catch UI issues quickly and ensure your designs adhere to best-practice UI guidelines,
                including Refactoring UI and Nielsen Norman. Quality check your UI before delivering
                to engineering or clients.
              </p>
              <a
                href="https://www.figma.com/community/plugin/1288854102538019029/designpro-ai-task-manager"
                className="button-4 w-button"
              >
                Install the Figma Plugin
              </a>
            </div>
          </div>
          <div className="column-13">
            <div className="image-wrapper-10">
              <img
                src={polishUI}
                loading="lazy"
                sizes="(max-width: 479px) 90vw,
                (max-width: 767px) 94vw,
                (max-width: 991px) 95vw,
                (max-width: 1439px) 50vw,
                720px"
                alt=""
                className="feature-image"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="features" className="section-1">
        <div className="container-3">
          <div className="column-12">
            <div className="content-3">
              <h1 className="h2">
                🎯 Drive conversions
              </h1>
              <p className="h6">
                Boost conversions on your websites and sign-up screens with clear
                Call-to-Action buttons, copy, and UI to help guide users
                effectively through your design.
              </p>
            </div>
          </div>
          <div className="column-13">
            <div className="image-wrapper-10">
              <img
                src={conversion}
                loading="lazy"
                sizes="(max-width: 479px) 90vw,
                (max-width: 767px) 94vw,
                (max-width: 991px) 95vw,
                (max-width: 1439px) 55vw, 720px"
                alt=""
                className="feature-image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="logo-title-2">
        <div className="column-6">
          <div className="content">
            <h1 className="h2 align-center">
              Trained on best-practices in design
            </h1>
            <p className="h6 align-center">
              DesignPro's AI is trained using hundreds of guidelines from Nielsen Norman,
              Refactoring UI, Material Design and IDEO. It provides the highest quality
              reviews to help your team enhance your designs quickly and efficiently.
              <br />
            </p>
          </div>
          <div className="image-wrapper-6">
            <img
              src={testimonials}
              loading="lazy"
              sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 1439px) 95vw, 1199.9765625px"
              alt=""
              className="image-62"
            />
          </div>
        </div>
      </div>
      <div data-w-id="53824dd9-af05-1378-ec27-b629afd5e948" className="testimonial-column-light">
        <div className="testimonial-header">
          <img
            src={spiral}
            loading="lazy"
            width="89"
            alt=""
            className="spiral-2"
          />
          <div className="h3 align-center">
            Product and marketing teams love using DesignPro
          </div>
        </div>
        <div className="columns f2wf-columns-2">
          <div className="column">
            <div className="testimonial">
              <div className="quote">
                <div className="text-5">
                  ”DesignPro helps my team conduct a pre-review before our
                  formal reviews. It ensures we've addressed any errors or
                  mistakes before presenting our final work.”
                </div>
                <div className="author">
                  <div className="avatar">
                    <img
                      src={alan}
                      loading="lazy"
                      alt=""
                      className="avatar-image"
                    />
                  </div>
                  <div className="info">
                    <div className="text-6">
                      Alan
                    </div>
                    <div className="text-7">
                      Marketing, ByteDance
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="testimonial">
              <div className="quote-2">
                <div className="text-5">
                  “This tool is incredibly useful as it ensures that my junior
                  designers work consistently and deliver high-quality results,
                  freeing my mind for more strategic decisions.”
                </div>
                <div className="author">
                  <div className="avatar">
                    <img
                      src={danis}
                      loading="lazy"
                      width="200"
                      alt=""
                      className="avatar-image"
                    />
                  </div>
                  <div className="info">
                    <div className="text-6">
                      Danis

                    </div>
                    <div className="text-7">
                      Design Manager, Grab
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="testimonial">
              <div className="quote">
                <div className="text-5">
                  “DesignPro aids my team in delivering websites and content
                  that drives conversions, ultimately fulfilling my clients'
                  business goals.”
                </div>
                <div className="author">
                  <div className="avatar">
                    <img
                      src={kuber}
                      loading="lazy"
                      alt=""
                      className="avatar-image"
                    />
                  </div>
                  <div className="info">
                    <div className="text-6">
                      Kuber

                    </div>
                    <div className="text-7">
                      Founder, Rasta
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="image-wrapper-8" />
      </div>
      <div id="pricing" className="pricing-comparison">
        <div className="column-6">
          <div className="content">
            <h1 className="h2 align-center">
              Turn designs into outcomes
            </h1>
            <p className="h6 align-center">
              DesignPro enhances designs by enabling quicker feedback cycles to save time and money.
            </p>
          </div>
        </div>
        <div className="columns-2 f2wf-columns-2">
          <img
            src={doodleString}
            loading="lazy"
            alt=""
            className="doodle-13"
          />
          <img
            src={doodle}
            loading="lazy"
            alt=""
            className="doodle-15"
          />
          <img
            src={doodle}
            loading="lazy"
            alt=""
            className="doodle-14"
          />
          <div className="pricing-plan">
            <div className="intro">
              <div className="name">
                <div className="text-8">
                  Free (Beta)
                </div>
              </div>
              <div className="pricing">
                <div className="_599-mo">
                  $0
                </div>
                <div>
                  per user / month
                </div>
              </div>
            </div>
            <div className="separator" />
            <div className="features">
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src={checkIcon}
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  10 reviews per month
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src={checkIcon}
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Copy, Accessibility, UI and Conversions
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src={checkIcon}
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Figma Plugin
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src={checkIcon}
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  30 day search history
                </div>
              </div>
            </div>
            <Login
              className="width-full flex text-center"
              loginButtonClassName="button primary"
              fromPluginAuth={fromPluginAuth}
            >
              <div className="button-label-2">
                Sign-in to try for free
              </div>
            </Login>
          </div>
          <div className="pricing-plan">
            <div className="intro">
              <div className="name">
                <div className="text-8">
                  Pro (Coming Soon)
                </div>
              </div>
              <div className="pricing">
                <div className="_599-mo">
                  $8
                </div>
                <div>
                  per user / month
                </div>
              </div>
            </div>
            <div className="separator" />
            <div className="features">
              <div className="pricing-feature">
                <div className="feature-item">
                  <strong>
                    Everything in Free, and
                  </strong>
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src={checkIcon}
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Unlimited reviews
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src={checkIcon}
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Export to Jira, Notion
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src={checkIcon}
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Standalone app - sync with
                  <br />
                  Figma, Canva, Miro & Webflow
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src={checkIcon}
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  60 day search history
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src={checkIcon}
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Invite collaborators
                </div>
              </div>
            </div>
            <Login
              className="width-full flex text-center"
              loginButtonClassName="button primary"
              fromPluginAuth={fromPluginAuth}
            >
              <div className="button-label-2">
                Sign-in to try for free
              </div>
            </Login>
          </div>
        </div>
        <div className="image-wrapper-9" />
      </div>
      <div className="footer">
        <div className="columns-3 f2wf-columns-3">
          <div className="column-10">
            <div className="logo-wrapper-2">
              <div className="logo-3">
                <NavbarLogo />
              </div>
            </div>
          </div>
          <div className="small-columns f2wf-small-columns">
            <div className="column-11">
              <div className="content-9">
                <div className="footer-links">
                  <Link className="footer-link" to={ROUTES.TERMS_AND_CONDITIONS}>
                    Terms and Conditions
                  </Link>
                  <Link to={ROUTES.PRIVACY_POLICY} className="footer-link">
                    Privacy Policy
                  </Link>
                  <div className="text-10">
                    ©
                    {' '}
                    {
                      getCurrentYear()
                    }
                    {' '}
                    Creative Labs, Inc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function handleShowNavMenu() {
    document.querySelector('.w-nav-button').classList.toggle('w--open');
    document.querySelector('.w-nav-overlay').classList.toggle('w-nav-overlay-show');
  }
};

LandingPageUI.propTypes = {
  installButton: PropTypes.node,
  InstallExtensionButton: PropTypes.node,
  fromPluginAuth: PropTypes.bool,
};
