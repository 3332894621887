import axios from 'axios';

import actionTypes from 'Redux/constants';

import { PLUGIN_SOURCE } from 'utlis/constants';
import { setAuthorizationToken } from 'utlis/http';

const baseURL = process.env.REACT_APP_BASE_URL;

export const verifyUserEmail = async email => axios.post(`${baseURL}/api/v1/user/user_exists/`, { email });

export const signUpUser = async (userId, pluginSource, writeKey, idToken) => {
  axios.post(`${baseURL}/api/v1/user/custom/signup/`, {
    'user_id': userId,
    'request_plugin_source': pluginSource ? PLUGIN_SOURCE[pluginSource] : PLUGIN_SOURCE.taskGen,
    'write_key': writeKey,
  }, {
    headers: {
      'Id-Token': idToken,
    },
  });
};

export const extensionSignUpUser = async (pluginSource, writeKey, idToken) => axios.post(`${baseURL}/api/v1/user/extension/custom/signup/`, {
  'request_plugin_source': pluginSource ? PLUGIN_SOURCE[pluginSource] : PLUGIN_SOURCE.taskGen,
  'write_key': writeKey,
}, {
  headers: {
    'Id-Token': idToken,
  },
});

export const loginUser = async (pluginSource, writeKey, idToken, dispatch) => axios.post(`${baseURL}/api/v1/user/custom/login/`, {
  'request_plugin_source': pluginSource ? PLUGIN_SOURCE[pluginSource] : PLUGIN_SOURCE.taskGen,
  'write_key': writeKey,
}, {
  headers: {
    'Id-Token': idToken,
  },
}).then(response => {
  dispatch({
    type: actionTypes.SIGN_IN_USER,
    payload: response.data,
  });

  window.localStorage.setItem('userObject', JSON.stringify(response.data));
  window.localStorage.setItem('access_token', response?.data.access);
  window.localStorage.setItem('refresh_token', response?.data.refresh);
  window.localStorage.setItem('authenticated', true);

  setAuthorizationToken(response?.data.access);

  return response.data;
});
