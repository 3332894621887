import figmaLogo from 'assets/figma_logo.svg';

import styles from './styles.module.scss';

export const InstallPluginButton = () => (
  <a
    className={styles['button-container']}
    href="https://www.figma.com/community/plugin/1339202278007297015/ai-web-design-reviewer-copy-layout-hierarchy-ui"
  >
    <div className={styles['logo-container']}>
      <img
        className={styles['button-logo']}
        height={16}
        width={16}
        src={figmaLogo}
        alt="Install Button"
      />
    </div>
    <span
      className={styles['button-text']}
    >
      Install the Figma Plugin
    </span>
  </a>
);
